@import '../shared/style/Variables.scss';

.footer-wrapper {
    background: $primary-color;

    .ant-row {
        margin: 0 !important;
    }

    span {
        color: white;
        font-size: 12px;
    }

    .primary-footer {
        padding: 10px;
        background-color: $primary-color;

        span {
            color: white;
            font-size: 16px;
            line-height: 32px;
        }

        svg {
            color: white;
            vertical-align: sub;
        }

        .links {
            .link {
                padding: 0 20px;
                display: inline-block;
                height: 31px;
                cursor: pointer;
            }
        }
    }

    .secondary-footer {
        padding: 10px;
    }
}

.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}

.home-header-row-2 {
    height: 60px;
    padding: 0 20px;

    .ant-checkbox-wrapper {
        line-height: 30px;
    }

    .ant-row {
        height: 100%;

        svg {
            vertical-align: bottom;
        }

        span,
        a {
            font-size: 12px;
        }
    }

    .search-bar {
        box-shadow: 0 4px 6px 1px rgba(0, 0, 0, 0.05);

        .ant-input-affix-wrapper {
            padding: 5px 15px;
            border-bottom-left-radius: 5px !important;
            border-top-left-radius: 5px !important;

            input {
                font-size: 14px;
            }
        }

        button {
            border-bottom-right-radius: 5px !important;
            border-top-right-radius: 5px !important;
            height: 36px;
            padding-top: 4px;
        }
    }
}

.home-header-row-3 {
    background-color: black;
    padding: 15px 20px;
    text-align: center;

    .menu-items {
        text-align: center;

        .menu-item {
            padding: 0 10px;
            display: inline-block;
        }
    }

    a {
        color: white;
    }
}

.ant-table-scroll-horizontal {
    table {
        width: 100% !important;
    }
}

.header-tabs {
    .ant-tabs-nav {
        margin-bottom: 0px !important;
        .ant-tabs-nav-list {
            .ant-tabs-tab {
                //version change
                /*&:nth-last-of-type(2){
          margin: 0;
        }*/
                .ant-tabs-tab-btn {
                    color: #fff;
                    font-size: 1.25rem;
                    .header-icon-img {
                        max-height: 1.1rem;
                        vertical-align: baseline;
                        margin-right: 8px;
                    }
                    &:hover {
                        color: $primary-color;
                        .header-icon-img {
                            fill: $primary-color;
                        }
                    }
                }
            }
        }
    }
}

.header-user-text {
    color: #fff;
    display: flex;
    flex-direction: column;
    span {
        font-size: 1rem !important;
        font-weight: 400 !important;
        line-height: 1 !important;
    }
}

.btn-spin-wrapper {
    .ant-spin-dot-item {
        background-color: #ffeddf;
    }
}

.page-title {
    font: normal normal 600 28px/32px $font-primary;
    margin-bottom: 1.5rem;
}

@media screen and (max-width: 1024px) {
    header {
        position: relative !important;
        height: 100% !important;
        .ant-row {
            justify-content: center !important;
        }
        .logo-text {
            font-size: 1.4em !important;
            line-height: 1em !important;
        }
        .ant-tabs-tab {
            color: #000;
        }
        .ant-tabs-tab-btn {
            font-size: 1.2em !important;
        }
    }
    .desktop-layout .content-layout {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }
}
