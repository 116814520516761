@import 'shared/style/Variables.scss';



.register {
  min-height: 100vh;
  .register-form-row{
    min-height: 100vh;
    .register-form-wrapper-column{
      background-color: #000;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .register-form-wrapper {
        .form-logo{
          max-height: 45px;
          margin-bottom: 10px;
        }
        .logo-subtitle{
          font-size: 1.3rem;
          color: #fff;
          margin-bottom: 35px;
        }
        .register-form{
          max-width: 400px;
          color: #fff;
          label{
            color: #fff !important;
            white-space: initial !important;
          }

          .ant-radio-wrapper{
            display: inline-flex;
            align-items: center;
          }
          .ant-checkbox-wrapper{
            display: inline-flex;
            align-items: center;
          }
        }
      }
    }
    
    .register-text-column{
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .register-text-wrapper{
        padding: 0 20px;
        h2{
          text-align: center;
          color: #45544c;
        }
        .video-wrapper{
          text-align: center;
        }

        .register-grid-wrapper{
          max-width: 400px;
          margin: 30px auto 0;
          .register-grid{
            display: grid;
            .grid-item{
              text-align: center;
              padding: 20px 5px;
              h1{
                font-size: 1.8rem;
                line-height: 1;
                margin-bottom: 0;
              }
              div{color: #45544c;}
              &:nth-child(1){
                grid-column: 1;
                background-color: #a5dad4;
                h1{color: #34b3a7;}
              }
              &:nth-child(2){
                grid-column: 2;
                background-color: #fcc99e;
                h1{color: #e8833a;}
              }
              &:nth-child(3){
                background-color: #c5dd8e;
                h1{color: #9fc204;}
              }
              &:nth-child(4){
                background-color: #9bd2f1;
                h1{color: #09a2d9;}
              }
            }
          }
        }
      }
    }
  }
}
