@import '../shared/style/Variables.scss';

.technical-details-wrapper{
  padding: 15px;
  .title-bar{
    .title{
      font: normal normal 600 28px/24px $font-primary;
      color: $primary-color;
    }
  }
  .header-buttons{
    .black-button{
      font-size: 16px;
      font-weight: 600;
      padding: 4px 20px;
    }
  }

  .bold-subtitle{
    font-weight: bold;
    font-size: 1.2rem;
    color: #000;
    margin-bottom: 0.5rem;
  }

  .title{
    font-size: 1.3rem;
    color: $primary-color;
    font-weight: bold;
  }

  .left-pane, .right-pane{
    background-color: #F1F1F1;
    max-height: calc(100vh - 275px);
    overflow: auto;
    padding: 25px;
  }

  .left-pane{
    .ant-card{
      .project-list{
        li{
          a{
            color: #000;
            text-decoration: underline;
          }
        }
        li + li{
          margin-top: 0.3rem;
        }
      }
    }

    .challenge-switches-wrapper{
      .ant-btn{
        //width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 1.5rem;
      }
    }
  }
  .right-pane{
    .stikcy-title{
      border-bottom: 1px solid #000;
      position: sticky;
      top: 0;
      background-color: inherit;
      padding: 0px 0px 15px;
      z-index: 1;
      margin-bottom: 15px;
    }
    .question-card-wrappeleft-pane-bottom-innerr{
      padding: 15px;
      .question-card{}
      .question-card + .question-card{
        border-top: 1px solid #000;
        padding-top: 1rem;
      }
    }
  }
}


.question-title{
  font-size: 1.3rem;
  color: $primary-color;
  font-weight: bold;
  margin-bottom: 0.25rem;
}


  @media screen and (max-width: 768px) {
     
      .technical-details-wrapper {
          div {
              div:nth-child(1) {
                  padding: 0;
                  display: flex;
                  flex-direction: column;
                  gap: 25px;
              }              
          }
      }
     .left-pane{
      padding: 20px !important;
      margin-bottom: 20px;
      .left-pane-bottom{
        margin-top:20px ;
      .left-pane-bottom-inner{
        padding: 20px;
        div{
          gap: 15px;

        }
      }
      }
     }

     .technical-details-wrapper .left-pane, .technical-details-wrapper .right-pane{
      max-height: 100%;

     }
     .answer{
      display: flex;
      justify-content: center;
     }
     .chart-wrapper{
      align-items: center;
     }
     .right-pane{
      text-align: left !important;
      .title{
        padding: 20px !important;
      }
      .stikcy-title{
        border-bottom: 1px solid #000;
      }
      .question-card{
        padding: 20px;
      }
     }
  }
  
  