.franchise-wrapper {
}

.claim-logo {
    height: 64px;
    width: 64px;
    object-fit: contain;
    background-color: #f4f4f4;
}
.claim-admin-details-wrapper {
    background-color: #f5f5f5;
    padding: 10px;
    h3 {
        color: #979797;
    }
}

.ant-table-filter-dropdown-btns {
    .ant-btn {
        padding: 0px 3px;
    }
}

@media screen and (max-width: 780px) {
    .filterBox {
        flex-direction: column !important;
    }
}
