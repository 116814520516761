.company-logo {
  height: 64px;
  width: 64px;
  object-fit: contain;
  background-color: #F4F4F4;
}
.company-admin-details-wrapper{
  background-color: #F5F5F5;
  padding: 10px;
  h3{
    color: #979797;
  }
}

.ant-table-filter-dropdown-btns{
  .ant-btn{
    padding: 0px 3px;
  }
}
