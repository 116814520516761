@import '../shared/style/Variables.scss';

.financial-details-wrapper {
    //padding-top: 15px;
    //padding-bottom: 15px;
    padding: 15px;
    .title-bar {
        .title {
            font: normal normal 600 28px/24px $font-primary;
            color: $primary-color;
        }
    }
    .header-buttons {
        .black-button {
            font-size: 16px;
            font-weight: 600;
            padding: 4px 20px;
        }
    }
}

.ml-2 {
    margin-left: 1rem;
}

.check-icon {
    width: 16px !important;
    height: 16px !important;
}
