.admin-list-layout {
    .title-bar {
        padding: 15px;

        .icon {
            svg {
                font-size: 30px;
            }
        }

        .ant-typography {
            margin: 0;
        }
    }
}
@media screen and (max-width: 1024px) {
    .admin-list-layout {
        .ant-row {
            margin: 0 !important;
            display: flex !important;
            justify-content: end !important;
            gap: 15px !important;
            .text-right {
                ul {
                    li {
                        margin: 5px !important;
                    }
                }
            }
        }
    }
}



@media screen and (max-width: 768px) {
    .admin-list-layout {
        .ant-row {
            margin: 0 !important;
            display: flex !important;
            flex-direction: column !important;
            gap: 15px !important;
            .text-right {
                text-align: center !important;
                ul {
                    li {
                        margin: 5px !important;
                    }
                }
            }
        }
    }
}
