@import '../shared/style/Variables.scss';
.claim-overview-wrapper {
    padding-top: 15px;
    .overview-detail-table-column {
        .overview-detail-table {
            width: 100%;
            th {
                text-align: right;
                padding-right: 30px;
            }
        }
        &:not(:last-of-type) {
            border-right: 1px solid #707070;
        }
    }
}

.move-left {
    margin-left: -30px;
}

.steps {
    font-weight: 600;
    font-size: 18px;
    &.active {
        color: $primary-color;
    }
}
.rowGap {
    margin: 50px 0;
}
.black-button {
    background-color: #000;
    border-color: #000;
    height: auto;
    border-radius: 50px;
    &.lg {
        padding: 10px 45px 10px 15px;
    }
    &:hover,
    &.active,
    &:focus {
        background-color: rgba(#000, 0.85);
        border-color: #000;
    }
    * {
        line-height: 1;
        color: #fff;
    }
    img {
        height: 50px;
        margin-right: 20px;
    }
    h2 {
        font-size: 30px;
        margin-bottom: 0;
        color: #fff;
    }
}

.financial-technical-row > .ant-col + .ant-col {
    border-left: 1px solid #707070;
}
.finalizeSubmitBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}

@media screen and (max-width: 780px) {
    .financial-technical-row > .ant-col + .ant-col {
        border: none;
    }
    .claim-overview-wrapper .overview-detail-table-column:not(:last-of-type) {
        border-right: none;
    }
    .claim-overview-wrapper
        .overview-detail-table-column
        .overview-detail-table
        th {
        text-align: left;
    }
    .claim-overview-wrapper
        .overview-detail-table-column
        .overview-detail-table
        td {
        text-align: right;
    }
}

/*.border-right-gray{
  border-right: 1px solid #707070;
}*/
