.franchise-wrapper {

}

.profile_section {
    display: flex;
    justify-content: space-around;
    h1 {
        font-size: 22px;
        font-weight: 600;
        margin: 0px;
    }

}

.profile-submit {
    display: flex;
    justify-content: end;
}
