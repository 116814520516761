.chartWrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    padding: 0 20px;
}

.chart {
    min-width: 320px;
    flex: 1;
    background-color: #fff;
    padding: 20px;
    border-radius: 7px;
    box-shadow: 0px 4px 4px rgba(128, 125, 125, 0.3);
}
.chart__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
h3 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}
.verified-users {
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
}
.verified-users-left,
.verified-users-right {
    width: 100%;
}
.verified-users-left {
    border-right: 1px solid grey;
    width: 35%;
}
.verified-users-right {
    width: 65%;
}

.verified-users-left p,
.verified-users-right p {
    font-size: 20px;
    margin: 0;
}
.verified-users-left p:nth-child(2) {
    font-weight: 700;
}
.verified-users-left p:last-child,
.verified-users-right p > span {
    font-size: 45px;
    font-weight: 700;
    color: #70b7e1;
}

.verified-users-right p {
    font-size: 30px;
    text-align: center;
}
.verified-users-right p > span {
    color: #ef8030;
}

.verified-users-donut-container {
    display: flex;
    flex-direction: row;
}

.verified-users-donut-container div {
    flex: 0.5;
}

.deadline-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 768px) {
    .chartWrapper {
        flex-direction: column;
        .chart {
            #chart {
                margin: 0 auto;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    h3 {
        font-size: 19px;
    }
    .verified-users {
        flex-direction: column;
    }
    .verified-users-left,
    .verified-users-right {
        width: 100%;
    }
    .verified-users-donut-container {
        flex-direction: column;
    }
    .chartWrapper {
        .chart {
            #chart {
                margin: 0 !important;
            }
        }
    }
}
