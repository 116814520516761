.DeadlineContainer {
    cursor: pointer;
    margin-top: 20px;
    box-shadow: 0px 4px 4px rgba(128, 125, 125, 0.3);
    padding: 30px 40px;
    border-radius: 7px;
    background-color: #f5f5f5;
}
.DeadlineContainer p {
    font-size: 50px;
    margin: 0;
    line-height: 1em;
    font-weight: 800;
    color: #323232;
}
.DeadlineContainer p:last-child {
    line-height: 1em;
    font-size: 20px;
    font-weight: normal;
}
@media screen and (max-width: 480px) {
    .DeadlineContainer {
        width: 70%;
    }
}
