.analytics {
    display: flex;
    width: 100%;
    gap: 20px;
    flex-wrap: no-wrap;
    max-height: 84vh;
    overflow: hidden;
    h3 {
        margin-bottom: 10px;
    }
}
.analytics-left,
.analytics-right {
    padding: 30px;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 4px 4px rgba(128, 125, 125, 0.3);
}
.analytics-left {
    width: 25%;
    .external-deadline {
        margin-top: 25px;
    }
}
.analytics-right {
    width: 75%;
    text-align: center;
    h3 {
        padding: 15px 0 0px;
        font-size: 2em;
    }
}
.analytic-right-top {
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 20px;
}

.analyticsBtn {
    margin: 25px 0;
    color: white;
}

@media screen and (max-width: 1024px) {
    .analytics {
        padding: 20px;
        flex-wrap: wrap;
        max-height: 100% !important;
        overflow: auto;
        h3 {
            font-size: 18px;
        }
        .analytics-left {
            width: 100%;
        }
        .analytics-right {
            width: 100%;
            .analytic-right-top {
                flex-wrap: wrap;
            }
        }
    }
}
