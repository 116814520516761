@import '../shared/style/Variables.scss';

.financial-details-wrapper {
    //padding-top: 15px;
    //padding-bottom: 15px;
    padding: 15px;
    .title-bar {
        .title {
            font: normal normal 600 28px/24px $font-primary;
            color: $primary-color;
        }
    }
    .header-buttons {
        .black-button {
            font-size: 16px;
            font-weight: 600;
            padding: 4px 20px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .header-buttons {
        text-align: center;
    }
}

@media screen and (max-width: 768px) {
    .addEmployeeBox {
        display: flex !important;
        flex-direction: column !important;
        gap: 15px;
    }
    .financial-details-wrapper {
        div {
            div:nth-child(1) {
                padding: 0;
                display: flex;
                flex-direction: column;
                gap: 25px;
            }

            .header-buttons {
                display: flex !important;
                flex-direction: column !important;
                align-items: center !important;
                span {
                    div {
                        gap: 0 !important;
                    }
                }
            }
        }
    }
}

.pointer-none {
    pointer-events: none;
}
.cursor-not-allowed {
    cursor: not-allowed;
}
